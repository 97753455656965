// Variable overrides

$light: #FAFAFA;

// $primary-color: #1565C0;
// $primary-color: #159EC3;
// $primary-color: #1565c0;
$primary-color: #159EC3;
$error-red: #D21616;
$success-green: #2DCA71;
$warning-yellow: #F6B64B;
$info-blue: #159EC3;
$value-gray: #FAFAFA;

$primary: $primary-color;
$success: $success-green;
$danger: $error-red;
$warning: $warning-yellow;
$info: $info-blue;
